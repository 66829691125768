body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFF6F1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-root{
  display: inline-block;
  width: 100%;
}

.div-row{
  width: 100%;
  display: inline-block;
}

.mobile-menu-container {
  position: relative;
    display: inline-block;
    width: 100%;
    height: 60px;
    border-bottom: 2px solid #000;
}

.menu-icon {
  font-size: 30px;
  cursor: pointer;
  padding: 10px;
  float: right;
}

.menu-items {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 62px;
  right: 0;
  background-color: #FFF6F1;
  min-width: 200px;
  max-width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.menu-items a {
  color: #000;
  font-weight: bold;
  padding: 15px 20px;
  text-decoration: none;
  border-bottom: 1px solid #444;
}

.menu-items a:hover {
  background-color: #F7B801;
}

.main-root h1{
  display: inline-block;
  width: 100%;
  text-align: center;
}
.main-root h2{
  display: inline-block;
  width: 100%;
  text-align: center;
}
.main-menu-logo{
  display: inline-block;
  height: 50px;
  float: left;
  line-height: 50px;
}

.main-menu-logo .main-menu-logo-text{
  display: inline-block;
  float: right;
  color: #000;
  line-height: 60px;
  text-decoration: none;
  font-weight: bold;
}

.main-menu-logo .main-menu-logo-img{
  display: inline-block;
  width: 30px;
  line-height: 60px;
  vertical-align: middle;
  padding: 0 10px;
}

.category-container{
  display: inline-block;
  width: 100%;
  text-align: center;
}

.category-container ul{
  list-style-type: none;
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
}

.category-container ul li{
  padding: 10px;
}

.category-container ul li a{
  font-weight: bold;
    color: #000;
    text-decoration: none;
    border: 1px solid #000;
    display: inline-block;
    padding: 10px;
    background-color: #F7B801;
}

.article-container{
  display: inline-block;
  padding: 10px;
}

.article-container .article-paragraph{
  display: inline-block;
  padding: 10px 15px;
  margin: 0;
}

.article-container .article-paragraph p{
  display: inline-block;
  margin: 0;
}

.privacy-policy-container{
  display: inline-block;
  width: 100%;
}

.privacy-policy-container p{
  display: inline-block;
  width: 95%;
  margin: 10px 0;
  padding: 0 2.5%;
}

.contact-container{
  display: inline-block;
  width: 100%;
}

.contact-container p{
  display: inline-block;
  width: 95%;
  margin: 10px 0;
  padding: 0 2.5%;
}

.about-container{
  display: inline-block;
  width: 100%;
}

.about-container p{
  display: inline-block;
  width: 95%;
  margin: 10px 0;
  padding: 0 2.5%;
}

.home-container{
  display: inline-block;
  width: 100%;
}

.home-container .home-categories-list-container{
  display: inline-block;
  width: 100%;
}

.home-container .home-categories-list-container .home-category-item{
  display: inline-block;
  width: 50%;
  text-align: center;
}

.home-container .home-categories-list-container .home-category-item h2{
  display: inline-block;
  width: 100%;
}

.home-container .home-categories-list-container .home-category-item h2 a{
  background-color: #000;
  border: 5px solid #f7b801;
  color: #f7b801;
  font-weight: 700;
  text-decoration: none;
  padding: 10px;
  display: inline-block;
}

.home-container .home-categories-list-container .home-category-item ul{
  display: inline-block;
  width: 100%;
  text-align: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.home-container .home-categories-list-container .home-category-item ul li{
  margin: 10px 0;
  display: inline-block;
  padding: 0 10px;
  text-align: center;
}

.home-container .home-categories-list-container .home-category-item ul li p{
  padding: 10px;
  margin: 0;
  max-width: 400px;
  display: inline-block;
}

.home-container .home-categories-list-container .home-category-item ul a{
  background-color: #f7b801;
  border: 1px solid #000;
  color: #000;
  font-weight: 700;
  text-decoration: none;
  padding: 10px;
  display: inline-block;
}

@media only screen and (max-width: 1000px) {
  .home-container .home-categories-list-container .home-category-item{
    width: 100%;
  }
}

@media only screen and (max-width: 450px) {
  .main-menu{
    height: 100px;
  }

  .main-menu .main-menu-nav{
    width: 100%;
    float: none;
    border-top: 1px solid;
  }

  .main-menu .main-menu-nav nav {
    width: 100%;
    text-align: center;
  }

  .main-menu .main-menu-nav nav ul li {
     border-left: 0; 
  }
}